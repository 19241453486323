import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo, Text } from 'components';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import { OrderSummary } from '../shipping/components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import LCFooter from 'components/LCFooter';
import { mobile, tablet } from 'styles/breakpoints';
import Tracking from 'utils/tracking';
import AppleStoreLinkBtn from './components/AppleStoreButton';
import GooglePlayLinkBtn from './components/GooglePlayButton';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  background: #f9f9f9;
  min-height: calc(100vh - 72px);

  @media ${tablet} {
    padding: 1.3125rem 0 3.125rem;
    min-height: calc(100vh - 3.5rem);
  }
`;

const Title = styled(Text)`
  color: #2a4b42;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 130%;
  text-align: left;
  margin-bottom: 0.61225rem;
  @media ${tablet} {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #2a4b42;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const Star = styled(DynamicImage)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.822rem;
  @media ${tablet} {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const ModalContainer = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(7.5px);
  z-index: 999;
  opacity: ${({ isModalOpen }) => (isModalOpen ? '1' : '0')};
  visibility: ${({ isModalOpen }) => (isModalOpen ? 'visible' : 'hidden')};
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
`;

const Modal = styled.div`
  position: absolute;
  max-width: 26.75rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 1.25rem;
  background: #fff;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;

  @media ${mobile} {
    max-width: unset;
    bottom: 0;
    transform: translate(-50%, 0);
    border-radius: 1.25rem 1.25rem 0rem 0rem;
  }
`;

const ModalCloseButton = styled.button`
  border-radius: 6.25rem;
  background: #118b62;
  padding: 1rem;
  width: 100%;
  border: none;
  color: #fff;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 1.3rem */
  letter-spacing: -0.024rem;
  cursor: pointer;
`;

const ModalText = styled.p`
  color: #1c1c28;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  padding: 0 1rem;

  span {
    color: #118b62;
  }
`;

const StoreButtonsContainer = styled.div`
  display: flex;
  /* max-width: 21.4375rem; */
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  & > * {
    flex: 1 1 auto;
    min-width: calc(50% - 1rem);
  }
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');
  const shippingData = useQuizData('shipping');
  const {
    selected_plans,
    upsell_products,
    quiz_answers,
    selected_plans_options,
  } = useSelector((state: AppState) => state.user);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const hasOnlySubscription =
    selected_plans?.length === 1 && selected_plans[0]?.is_subscription;

  const boughtSub =
    selected_plans?.some(item => item.is_subscription) ||
    upsell_products?.some(item => item.is_subscription);

  usePageView();

  const handleModalToggle = () => {
    if (!data.modal) {
      return;
    }

    if (isModalOpen) {
      document.body.style.overflow = 'auto';
      setIsModalOpen(false);
      return;
    }

    document.body.style.overflow = 'hidden';
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    if (!data.modal) {
      return;
    }

    if (isModalOpen) {
      document.body.style.overflow = 'auto';
      setIsModalOpen(false);
      return;
    }
  };

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      {data.modal && (
        <ModalContainer isModalOpen={isModalOpen} onClick={handleModalClose}>
          <Modal>
            <ModalText dangerouslySetInnerHTML={{ __html: data.modal.text }} />
            <StoreButtonsContainer>
              <AppleStoreLinkBtn overrideLink={data.modal.appStoreLink} />
              <GooglePlayLinkBtn overrideLink={data.modal.googlePlayLink} />
            </StoreButtonsContainer>
            <ModalCloseButton
              onClick={handleModalToggle}
              disabled={!isModalOpen}
            >
              Back
            </ModalCloseButton>
          </Modal>
        </ModalContainer>
      )}

      <Wrapper>
        <Seo />
        <Header
          logoVariant="center"
          color={'grayBg'}
          hasBorder={true}
          sticky={false}
        />
        <Container>
          <TitleContainer>
            <Star alt="star" src="success/star_check.png" />
            <Title>{data.title}</Title>
            <Subtitle>{data.subtitle}</Subtitle>
          </TitleContainer>
          <OrderSummary
            productMap={shippingData.productMap}
            delayMessage={shippingData.delayMessage}
            bundleInfo={data.bundleInfo}
            isSuccess
            gifts={data.gifts}
            upsellsKeys={data?.upsellsKeys}
            bundlePhoto={data?.bundlePhoto}
            modalHandler={handleModalToggle}
          />
        </Container>
        {/* <LCFooter disclaimerText={data?.disclaimerParagraphs || []} /> */}
      </Wrapper>
    </>
  );
};

export default Success;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  max-width: 26.3rem;
  width: 100%;
  @media ${tablet} {
    max-width: unset;
    padding: 0 1rem 0.75rem;
  }
`;
