import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import { mobile, useQuery } from 'styles/breakpoints';
import { Text } from 'components';

interface DisclaimerProps {
  title?: string;
  list?: string[];
  tocAndPpTitle?: string;
  disclaimer: string;
  copyright: string;
  isWithPaymentMethods?: boolean;
  bgHex?: string;
}

const Container = styled.div<{ bgHex?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme, bgHex }) =>
    bgHex ? bgHex : theme.colors.lightGreen};
  padding: 0rem 1rem 0 1rem;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #1c1c2833;
  @media ${mobile} {
    padding-top: 0rem;
  }
`;
const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 55.375rem;
  margin: 1.5rem 1rem;
  @media ${mobile} {
  }
`;

const DisclaimerText = styled(Text)`
  padding: 0 0 0.5rem;
  color: #606069;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 0.5rem;
`;

const CopyrightText = styled(Text)`
  color: #606069;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 130%;
`;

const DisclaimerShipping: FC<DisclaimerProps> = ({
  copyright,
  disclaimer,
  bgHex,
}) => (
  <Container bgHex={bgHex}>
    <DisclaimerContainer>
      {!!disclaimer && (
        <DisclaimerText type="body" color="dark60">
          {disclaimer}
        </DisclaimerText>
      )}
      {!!copyright && (
        <CopyrightText type="body" color="dark60">
          {copyright?.replace('${year}', new Date().getFullYear().toString())}
        </CopyrightText>
      )}
    </DisclaimerContainer>
  </Container>
);

export default DisclaimerShipping;
