import React, { FC } from 'react';

import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

interface GooglePlayLinkBtnProps {
  overrideLink?: string;
}

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppleStoreBadge = styled(GooglePlayLogo)`
  height: 48px;
  /* width: 130px; */
`;

const GooglePlayLinkBtn: FC<GooglePlayLinkBtnProps> = ({
  overrideLink,
  ...props
}) => (
  <StyledLink
    href={overrideLink ?? StoresLinks.GooglePlay}
    target="_blank"
    {...props}
  >
    <AppleStoreBadge />
  </StyledLink>
);

export default GooglePlayLinkBtn;
