import React, { useEffect } from 'react';
import Header from '../../components/reviews/Header';
import MainReviewSection from 'components/reviews/MainReviewSection';
import VideoReviewsSection from 'components/reviews/VideoReviewsSection';
import SatisfiedUsersSection from 'components/reviews/SatisfiedUsersSection';
import SuccessStoriesSection from 'components/reviews/SuccessStoriesSection';
import styled from 'styled-components';
import Footer from 'components/reviews/Footer';
import { tablet } from 'styles/breakpoints';
import { Seo } from 'components';
import LCFooter from 'components/LCFooter';

const Reviews = () => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo
        title="Nova Spermidine Reviews"
        description="See how Nova health supplements helped our customers reverse their biological age and help with weight loss and hair health."
        name="Nova Spermidine Reviews"
      />
      <Header isMobileApp={false} />
      <MainReviewSection />
      <VideoReviewsSection />
      <SatisfiedUsersSection />
      <SuccessStoriesSection />
      <LCFooter
        disclaimerText={['© ${year} Nova Health. All rights reserved']}
      />
    </>
  );
};

export default Reviews;

export const StyledSection = styled.section<{
  backgroundColor: string;
  marginTop: string;
}>`
  height: fit-content;
  width: 100%;
  background: ${({ backgroundColor = '#f6f4ef' }) => backgroundColor};
  margin-top: ${({ marginTop = '69px' }) => marginTop};
  padding: 56px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${tablet} {
    padding: 38px 16px;
  }

  p,
  strong,
  a,
  h1,
  h2,
  span {
    font-family: Mulish, sans-serif;
  }

  p,
  strong,
  h1 {
    color: #000 !important;
  }
`;

export const SectionHeading = styled.h1<{ marginBottom: string }>`
  color: #000;
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '8px' }) => marginBottom};
`;

export const SectionSubheading = styled.h2<{ marginBottom: string }>`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #595b60;
  margin-top: 0;
  margin-bottom: ${({ marginBottom = '10px' }) => marginBottom};
`;
