import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, PrimaryButton, Seo } from 'components';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';

import Header from 'components/Header';
import { tablet } from 'styles/breakpoints';
import Arrow from 'assets/icons/results/green-arrow-right.svg';
import ResultsProgress from 'components/ResultsProgress';
import Checked from 'assets/icons/tick-circle.svg';

const ResultsChart: FC = () => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: AppState) => state.user);
  //   const dispatch = useDispatch();

  const { goToResultsReviews } = useRouter();

  usePageView();

  const handleClick = () => {
    setLoading(true);
    goToResultsReviews();
  };

  const data = useQuizData('resultsSupplement');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Outter>
        <Header color="novaPink" sticky={false} />
        <ResultsProgress
          step={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
      </Outter>
      <Container>
        <InnerContainer>
          <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
          <Subtitle>{data?.subtitle}</Subtitle>
          <DynamicImage src={data?.img} alt="Nova supplement info" />
          <StyledImg src={data?.img2} alt="Nova supplement info" />
          {data?.list && (
            <List>
              {data?.list.map((item, index) => (
                <ListItem key={index}>
                  <SvgContainer>
                    <CheckedStyled fill="#F47170" />
                  </SvgContainer>
                  {index === data?.list.length - 1 ? null : <Line />}
                  <ListInner>
                    <ListTitle>{item.title}</ListTitle>
                    <Text dangerouslySetInnerHTML={{ __html: item.text }} />
                  </ListInner>
                </ListItem>
              ))}
            </List>
          )}
          <StyledBottomImg src={data?.img3} alt="Nova supplement info" />
          <ButtonContainer>
            <ButtonStyled
              loading={loading}
              disabled={loading}
              onClick={handleClick}
            >
              <ArrowConatiner>
                <Arrow />
              </ArrowConatiner>
              {data?.buttonTitle}
            </ButtonStyled>
          </ButtonContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

export default ResultsChart;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  top: 0;
  z-index: 3;
`;

const Container = styled.section`
  padding: 3rem 1rem;
  background: #fef3f3;
  min-height: calc(100vh - 76px);
  @media ${tablet} {
    min-height: calc(100vh - 60px);
    padding: 1.5rem 1rem;
  }
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 26.25rem;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #2a4b42;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  span {
    color: #05a56f;
  }
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #2a4b42;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 1.5rem;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ArrowConatiner = styled.section`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 62.5rem;
  background: #fff;
  svg {
    width: 0.63538rem;
    height: 0.625rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  max-width: 28.26rem;
  margin: 0 auto 2rem;
`;

const ButtonStyled = styled(PrimaryButton)`
  width: 100%;
  div {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
`;

const List = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin: 1.5rem 0 0;
`;

const ListItem = styled.section`
  position: relative;
  display: flex;
`;
const ListInner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-left: 1.375rem;
`;

const StyledImg = styled(DynamicImage)`
  margin-top: 1rem;
`;

const ListTitle = styled.p`
  color: #1c1c28;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

const Text = styled.p`
  color: #1c1c28;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const StyledBottomImg = styled(DynamicImage)`
  max-width: 21.4375rem;
  width: 100%;
  margin: 1.5rem auto 2.625rem;
  @media ${tablet} {
    margin: 1.5rem auto 1.5rem;
  }
`;

const CheckedStyled = styled(Checked)`
  width: 0.75rem;
  height: 0.75rem;
`;

const SvgContainer = styled.section`
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 5px;
`;

const Line = styled.div`
  height: 108%;
  width: 1px;
  background-color: rgb(244, 113, 112);
  position: absolute;
  top: 17px;
  left: 5px;
`;
