import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  DynamicImage,
  LoadingPage,
  PrimaryButton,
  Seo,
  Text,
} from 'components';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';

import Header from 'components/Header';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import Arrow from 'assets/icons/results/green-arrow-right.svg';
import ChartSVG from 'assets/images/weight-loss/weight-loss-chart2.svg';
import ResultsProgress from 'components/ResultsProgress';
import { DateTime } from 'luxon';
import { Quiz } from 'types/quiz';

const ResultsChart: FC = () => {
  const [loading, setLoading] = useState(false);
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const { isMobile, isTablet } = useQuery();
  const now = new Date();
  const weightLossPerMonth = [
    { month: 1, percentValMonth: 9, dayValue: 0.3 },
    { month: 2, percentValMonth: 6.25, dayValue: 0.2083333333 },
    { month: 3, percentValMonth: 6.75, dayValue: 0.225 },
    { month: 4, percentValMonth: 4.7, dayValue: 0.1566666667 },
    { month: 5, percentValMonth: 5.3, dayValue: 0.1766666667 },
    { month: 6, percentValMonth: 4.7, dayValue: 0.1566666667 },
    { month: 7, percentValMonth: 4.4, dayValue: 0.1466666667 },
  ];

  const currentWeight = quiz_answers?.current_weight || '';
  const targetWeight = quiz_answers?.target_weight || '';
  const currentWeightUnit = quiz_answers?.current_weight_unit;
  const goalWeightUnit = quiz_answers?.target_weight_unit;

  const currentWeightFull = `${currentWeight} ${currentWeightUnit}`;
  const goalWeightFull = `${targetWeight} ${goalWeightUnit}`;

  const currentDate = DateTime.now();
  const getGoalDate = () => {
    const percentageLoss =
      ((currentWeight - targetWeight) / currentWeight) * 100;
    let totalDays = 0;
    let cumulativePercentageLoss = 0;

    for (let i = 0; i < weightLossPerMonth.length; i++) {
      const { percentValMonth, dayValue } = weightLossPerMonth[i];

      const remainingPercentageLoss = percentageLoss - cumulativePercentageLoss;

      // If the remaining percentage to lose is greater than or equal to this month's percentage target, add full month
      if (remainingPercentageLoss >= percentValMonth) {
        totalDays += 30; // Full month
        cumulativePercentageLoss += percentValMonth; // Add full month's percentage loss
      } else {
        // Add partial month based on dayValue and stop the loop
        totalDays += remainingPercentageLoss / dayValue;
        break;
      }

      // If we've lost enough weight, break the loop
      if (cumulativePercentageLoss >= percentageLoss) {
        break;
      }
    }
    const fixedDayNumber = Math.floor(totalDays);
    const futureDate = new Date(now);
    futureDate.setDate(now.getDate() + fixedDayNumber);

    return futureDate;
  };

  const formattedGoalDate = getGoalDate().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const getShortMonthNamesUntilGoal = () => {
    const now = new Date();
    const goalDate = getGoalDate();
    const monthNames = [];

    let current = new Date(now);

    // Iterate through months and include the last month even if goalDate is at the beginning
    while (
      current < goalDate ||
      (current.getMonth() === goalDate.getMonth() &&
        current.getFullYear() === goalDate.getFullYear())
    ) {
      const shortMonthName = current.toLocaleString('en-US', {
        month: 'short',
      });
      monthNames.push(shortMonthName);

      current.setMonth(current.getMonth() + 1);
    }

    return monthNames;
  };

  const calculateWeightSteps = () => {
    const totalLoss = currentWeight - targetWeight;
    const stepLoss = totalLoss / 7; // Divide by 7 to create 7 steps before reaching the target weight

    const weightSteps = [];
    for (let i = 0; i < 6; i++) {
      // Loop 6 times to get the first 6 intermediate weights
      const weight = currentWeight - stepLoss * i;
      weightSteps.push(Math.floor(weight)); // Use Math.floor() to round down
    }

    weightSteps.push(Math.floor(targetWeight)); // Add the target weight as the second-to-last element

    const finalWeight = Math.floor(targetWeight - stepLoss); // Calculate the last weight step
    weightSteps.push(finalWeight); // Add the final weight

    return weightSteps;
  };

  const weightList = calculateWeightSteps();

  const displayMonths = () => {
    const monthsList = getShortMonthNamesUntilGoal();
    const monthListLength = monthsList.length;

    if (monthListLength === 1) {
      return ['Today', monthsList[0]];
    }
    if (monthListLength === 2) {
      return ['Today', monthsList[1]];
    }
    if (monthListLength === 3) {
      return ['Today', monthsList[1], monthsList[2]];
    }

    return [
      'Today',
      monthsList[1],
      monthsList[monthListLength - 2],
      monthsList[monthListLength - 1],
    ];
  };

  const list = Array.from({ length: 6 }).map((_, i) => i);

  const { goToResultsInfo } = useRouter();

  usePageView();

  const handleClick = () => {
    setLoading(true);
    goToResultsInfo();
  };

  const data = useQuizData('resultsChart');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Outter>
        <Header color="novaPink" sticky={false} />
        <ResultsProgress
          step={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
      </Outter>
      <Container>
        <InnerContainer>
          <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
          <Subtitle>{data?.subtitle}</Subtitle>
          {quiz !== Quiz.Main && (
            <Box>
              <BoxTitle
                dangerouslySetInnerHTML={{
                  __html: data?.chartTitle
                    .replace('${date}', formattedGoalDate)
                    .replace('${weight}', `${targetWeight} ${goalWeightUnit}`),
                }}
              />
              <ChartContainer>
                <Current isMobile={isTablet}>{currentWeightFull}</Current>
                <Goal isMobile={isTablet}>{goalWeightFull}</Goal>
                <ChartSVGStyled />
                <CurrentWeightScale>{currentWeight}</CurrentWeightScale>
                <GoalWeightScale>{targetWeight}</GoalWeightScale>
                <MonthsContainer>
                  {displayMonths().map((month, index) => (
                    <Month key={index}>{month}</Month>
                  ))}
                </MonthsContainer>
              </ChartContainer>
            </Box>
          )}
          <ImgSection>
            <ImgTitle>{data?.imgTitle}</ImgTitle>
            <DynamicImage src={data?.img} alt="Chart" />
            <ImgSubtitle>{data?.imgSubtitle}</ImgSubtitle>
          </ImgSection>
          <ButtonContainer>
            <ButtonStyled
              loading={loading}
              disabled={loading}
              onClick={handleClick}
            >
              <ArrowConatiner>
                <Arrow />
              </ArrowConatiner>
              {data?.buttonTitle}
            </ButtonStyled>
          </ButtonContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

export default ResultsChart;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  top: 0;
  z-index: 3;
`;

const Container = styled.section`
  padding: 3rem 1rem;
  background: #fef3f3;
  min-height: calc(100vh - 76px);
  @media ${tablet} {
    min-height: calc(100vh - 60px);
    padding: 1.5rem 1rem;
  }
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 26.25rem;
  margin: 0 auto;
`;

const Title = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  span {
    color: #05a56f;
  }
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 1.5rem;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ArrowConatiner = styled.section`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 62.5rem;
  background: #fff;
  svg {
    width: 0.63538rem;
    height: 0.625rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  max-width: 28.26rem;
  margin: 0 auto 2rem;
`;

const ButtonStyled = styled(PrimaryButton)`
  width: 100%;
  div {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
`;

const ImgSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1rem;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px solid #eee;
  background: #fff;
  width: 100%;
  margin-bottom: 2.625rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const Box = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px solid #eee;
  background: #fff;
  width: 100%;
  margin-bottom: 1rem;
  @media ${tablet} {
  }
`;

const ImgTitle = styled(Text)`
  color: #1c1c28;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;

const BoxTitle = styled(ImgTitle)`
  padding: 0 1.14719rem;
  font-size: 1.375rem;
  span {
    display: block;
    color: #05a56f;
  }
`;

const ImgSubtitle = styled(Text)`
  color: #6c757d;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  padding-top: 1rem;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Weight = styled(Text)<{ isMobile?: boolean }>`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-align: left;

  position: absolute;
  color: white;
  font-size: ${({ isMobile }) => (isMobile ? '9px' : '12px')};
  line-height: ${({ isMobile }) => (isMobile ? '11px' : '16px')};
`;

const Current = styled(Weight)`
  top: 7.4%;
  left: 4%;
  width: 13.54%;
  text-align: center;
  @media ${mobile} {
    left: 4.5%;
  }
`;

const Goal = styled(Weight)`
  top: 45%;
  left: 65.3%;
  width: 9.66%;
  text-align: center;
`;

const MonthsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 16.684%;
  width: 62%;
  left: 10%;
  justify-content: space-between;
`;

const Month = styled(Text)`
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c757d;
  text-align: right;
`;

const CurrentWeightScale = styled(Month)`
  position: absolute;
  top: 16.8%;
  left: 3.2%;
`;
const GoalWeightScale = styled(Month)`
  position: absolute;
  top: 63.04%;
  left: 3.3%;
`;

const ChartSVGStyled = styled(ChartSVG)`
  max-width: 24.62rem;
  width: 100%;
  height: auto;
  padding-top: 5px;
  margin: 0 1rem;
`;
