import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';

interface ResultsFooterProps {
  text: string;
  footerEnd: string;
  bgColor?: string;
  fontColor?: string;
}

const StyledText = styled(Text)<{ fontColor?: string }>`
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  color: ${({ fontColor }) => fontColor || '#fff'};}
  width: 100%;
  max-width: 53.75rem;
`;

const Container = styled.section<{ bgColor?: string }>`
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  padding: 1rem 1rem 1.5rem; 1rem;
  background-color:${({ bgColor }) => (bgColor ? bgColor : '#436460')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterText = styled(Text)<{ fontColor?: string }>`
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${({ fontColor }) => fontColor || '#fff'};}
  width: 100%;
  max-width: 53.75rem;
  margin-top: 1rem;
`;

export const ResultsFooter: FC<ResultsFooterProps> = ({
  text,
  footerEnd,
  bgColor,
  fontColor,
}) => (
  <Container bgColor={bgColor}>
    <StyledText fontColor={fontColor}>{text}</StyledText>
    <FooterText fontColor={fontColor}>
      {footerEnd?.replace('${year}', new Date().getFullYear().toString())}
    </FooterText>
  </Container>
);
