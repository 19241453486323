import React, { FC } from 'react';

import AppleStoreLogo from 'assets/icons/appStore_badge.svg';
import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

interface AppleStoreLinkBtnPorps {
  overrideLink?: string;
}

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppleStoreBadge = styled(AppleStoreLogo)`
  height: 48px;
`;
const AppleStoreLinkBtn: FC<AppleStoreLinkBtnPorps> = ({
  overrideLink,
  ...props
}) => (
  <StyledLink
    href={overrideLink ?? StoresLinks.AppleStore}
    target="_blank"
    {...props}
  >
    <AppleStoreBadge />
  </StyledLink>
);

export default AppleStoreLinkBtn;
